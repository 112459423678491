export default {
  DELETE_CARD: 'DELETE_CARD',
  DELETE_CARD_LOADING: 'DELETE_CARD_LOADING',
  DELETE_CARD_LOADED: 'DELETE_CARD_LOADED',
  FETCH_PAYMENT_METHODS_LOADING: 'FETCH_PAYMENT_METHODS_LOADING',
  FETCH_PAYMENT_METHODS_LOADED: 'FETCH_PAYMENT_METHODS_LOADED',
  RESET_NEW_CARD_SAVED: 'RESET_NEW_CARD_SAVED',
  SAVED_CREDIT_CARD: 'SAVED_CREDIT_CARD',
  SET_ACH: 'SET_ACH',
  SET_ACH_ADDRESS: 'SET_ACH_ADDRESS',
  SET_ACH_URL: 'SET_ACH_URL',
  SET_ACTIVE_CARD: 'SET_ACTIVE_CARD',
  SET_ACTIVE_CARD_CVC: 'SET_ACTIVE_CARD_CVC',
  SET_ACTIVE_DEBIT_CARD: 'SET_ACTIVE_DEBIT_CARD',
  SET_CHARGE_DESCRIPTOR: 'SET_CHARGE_DESCRIPTOR',
  SET_CREDIT_CARD: 'SET_CREDIT_CARD',
  SET_CREDIT_CARDS: 'SET_CREDIT_CARDS',
  SET_DEBIT_PUBLIC_KEY: 'SET_DEBIT_PUBLIC_KEY',
  SET_DEBIT_CARDS: 'SET_DEBIT_CARDS',
  SWITCH_PAYMENT_METHOD: 'SWITCH_PAYMENT_METHOD',
  SET_PAYMENT_PROVIDERS: 'SET_PAYMENT_PROVIDERS',
  SET_DRIVER_TIP: 'SET_DRIVER_TIP'
}
