/**
 * Checkout reducer
 * It contains all the checkout related reducers
 */
import t from './actionTypes'

export const defaultState = {
  activeLocationId: '',
  address: {},
  allowCreditCards: false,
  depotMismatch: false,
  error: null,
  isACHDeclineDrawerOpen: false,
  isACHFixDrawerOpen: false,
  message: null,
  messageStatusCode: null,
  deliveryNotes: {
    selectedOption: null,
    otherMessage: '',
    aptNumber: ''
  },
  notes: '',
  paymentMethod: null,
  potentialAddress: {}
}

const checkout = (state = defaultState, action) => {
  switch (action.type) {
    case t.RESET_CHECKOUT:
      return defaultState

    case t.SET_CHECKOUT_ADDRESS:
      return Object.assign({}, state, {
        address: action.address
      })

    case t.SET_CHECKOUT_NOTES:
      return Object.assign({}, state, {
        notes: action.notes
      })

    case t.SET_DELIVERY_NOTES:
      return Object.assign({}, state, {
        deliveryNotes: action.deliveryNotes
      })

    case t.SEND_POST_ORDER:
      return Object.assign({}, state, {
        postOrderPending: true
      })

    case t.RECIEVE_POST_ORDER:
      return Object.assign({}, state, {
        postOrderPending: false
      })

    case t.SET_CHECKOUT_ERROR:
      return Object.assign({}, state, {
        error: action.payload
      })

    case t.SET_CHECKOUT_MESSAGE: {
      const { message, messageStatusCode } = action.payload
      return {
        ...state,
        message,
        messageStatusCode
      }
    }

    case t.CLEAR_CHECKOUT_MESSAGE:
      return {
        ...state,
        message: null,
        messageStatusCode: null
      }

    case t.CLEAR_CHECKOUT_ERROR:
      return Object.assign({}, state, {
        error: null
      })

    // Used only to fire analytics event for first vieing checkout
    case t.VIEW_CHECKOUT:
      return state

    // Used only to fire analytics event for checkout loading in locations with credit card depots
    case t.TRACK_CREDIT_OPTION:
      return state

    // Used to determine if the user has changed depots from checkout address picker
    case t.SET_DEPOT_MISMATCH:
      return Object.assign({}, state, {
        depotMismatch: action.value
      })

    case t.TOGGLE_CARD_DECLINE_DRAWER:
      return {
        ...state,
        isACHDeclineDrawerOpen: !state.isACHDeclineDrawerOpen
      }
    // Notification to re-link a bank account
    case t.TOGGLE_ACH_FIX_DRAWER:
      return {
        ...state,
        isACHFixDrawerOpen: !state.isACHFixDrawerOpen
      }
    default:
      return state
  }
}

export default checkout
