/**
 * User reducer, this is separated from auth because we want one central store that always contains these values and nothing else
 */

import t from './actionTypes'

export interface UserState {
  userId: string | number
  email: string
  firstName: string
  lastName: string
  xAuthToken: string
  zipCode: string
  mobilePhone: string
  expiredIdDate: string
}

export interface UserBasicInfo extends UserState {
  age: number
  contentId: string
  eligibilityProofType: number
  expirationDate: string
  isUsa: boolean
  verificationStatus: number
  verifiedAt: string
}

export const defaultState = {
  userId: '',
  email: '',
  firstName: '',
  lastName: '',
  xAuthToken: '',
  zipcode: '', // zip code user signed up with, required for berbix - should be using 'zipCode' to match API response
  mobilePhone: '', // required for berbix
  expiredIdDate: '' // should be 'expirationDate'?
}

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case t.REMOVE_USER:
      // called when (actions/auth) logout is called
      return defaultState

    case t.SET_USER_BASIC:
      // this can be triggered in a few places, coming from either (actions/profile) fetchUserProfile, or coming
      // from (actions/auth) recieveLoginSuccess
      return {
        ...state,
        userId: action.user.userId,
        email: action.user.email,
        firstName: action.user.firstName,
        lastName: action.user.lastName,
        mobilePhone: action.user.mobilePhone,
        zipcode: action.user.zipcode,
        expiredIdDate: action.user.expirationDate
      }

    case t.SET_USER_TOKEN:
      // this comes from (actions/auth) recieveLoginSuccess
      return {
        ...state,
        xAuthToken: action.token
      }

    default:
      return state
  }
}

export default userReducer
