/**
 * Verification flow reducer
 */

import t from './actionTypes'

export const defaultState = {
  // if an Identification image upload is still in progress
  isIdentificationLoading: false,
  // if a MMID image upload is still in progress
  isRecommendationLoading: false,
  err: {
    // errors for verification flow
    generic: null,
    // errors for image uploads
    upload: null
  },
  phone: {
    // if the initial verification code has been sent
    hasPhoneCodeBeenSent: false,
    // if a user has requested the verification code be resent
    hasPhoneCodeBeenResent: false,
    // the number currently being verified
    phoneNumber: ''
  },
  currentPageId: ''
}

const GENERIC_ERROR_MESSAGE = 'Something went wrong'
const UPLOAD_ERROR_MESSAGE = 'Image upload failed'

const verification = (state = defaultState, action) => {
  switch (action.type) {
    case t.RECEIVE_PHONE_CODE_SENT:
      return {
        ...state,
        phone: {
          ...state.phone,
          hasPhoneCodeBeenSent: true
        }
      }

    case t.RECEIVE_PHONE_CODE_ERROR:
      return {
        ...state,
        phone: {
          ...state.phone,
          hasPhoneCodeBeenSent: false
        },
        err: {
          generic: action.err || GENERIC_ERROR_MESSAGE
        }
      }

    case t.RESET_PHONE_CODE_ERROR:
      return {
        ...state,
        err: {
          generic: null
        }
      }

    case t.RECEIVE_PHONE_CODE_RESENT:
      return {
        ...state,
        phone: {
          ...state.phone,
          hasPhoneCodeBeenSent: true,
          hasPhoneCodeBeenResent: true
        },
        err: {}
      }

    case t.RESET_RECEIVE_PHONE_CODE_RESENT:
      return {
        ...state,
        phone: {
          ...state.phone,
          hasPhoneCodeBeenResent: false
        },
        err: {}
      }

    case t.REQUEST_UPLOAD_IDENTIFICATION:
      return {
        ...state,
        err: {},
        isIdentificationLoading: true
      }

    case t.RECEIVE_UPLOAD_IDENTIFICATION_ERROR:
      return {
        ...state,
        err: {
          upload: action.err || UPLOAD_ERROR_MESSAGE
        },
        isIdentificationLoading: false
      }

    case t.RECEIVE_UPLOAD_IDENTIFICATION_SUCCESS:
      return {
        ...state,
        err: {},
        isIdentificationLoading: false
      }

    case t.REQUEST_UPLOAD_RECOMMENDATION:
      return {
        ...state,
        err: {},
        isRecommendationLoading: true
      }

    case t.RECEIVE_UPLOAD_RECOMMENDATION_ERROR:
      return {
        ...state,
        err: {
          upload: action.err || UPLOAD_ERROR_MESSAGE
        },
        isRecommendationLoading: false
      }

    case t.RECEIVE_UPLOAD_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        err: {},
        isRecommendationLoading: false
      }

    case t.SET_PAGE:
      return {
        ...state,
        currentPageId: action.payload
      }

    case t.SET_PHONENUMBER:
      return {
        ...state,
        phone: {
          ...state.phone,
          phoneNumber: action.payload
        }
      }

    default:
      return state
  }
}

export default verification
