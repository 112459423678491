import { css } from '@emotion/react'

import { breakpoint, lightTheme } from '@/theme'

export const BaseStyles = css`
  @font-face {
    font-family: 'Suisse Intl Book';
    src: url(/static/fonts/suisseintl-book-webs.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Suisse Intl Bold';
    src: url(/static/fonts/suisseintl-bold-webs.woff) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Suisse Intl Mono Regular';
    src: url(/static/fonts/SuisseIntlMono-Regular-WebS.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Suisse Intl Mono Bold';
    src: url(/static/fonts/suisseintlmono-bold-webs.woff) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Neue Plak Extended Regular';
    src: url(/static/fonts/neue-plak-extended-regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Neue Plak Extended Bold';
    src: url(/static/fonts/neue-plak-extended-bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  :root {
    width: 100%;
    display: block;
  }
  * {
    box-sizing: border-box;
  }
  html,
  body,
  #__next {
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: ${lightTheme.typography.size.desktop.body3}px;
    font-family: ${lightTheme.typography.font.sim}, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
      helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
    color: ${lightTheme.colors.text};
  }
  @media (max-width: ${breakpoint.max.sm}px) {
    html,
    body,
    #__next {
      font-size: ${lightTheme.typography.size.mobile.body3}px;
    }
  }
  #__next {
    position: relative;
  }
  .loadingContainer {
    background-color: ${lightTheme.colors.beige};
  }
  .spinner {
    width: 72px;
    height: 100%;
    display: flex;
    align-self: center;
    color: ${lightTheme.colors.grey};
    font-size: ${lightTheme.typography.size.desktop.body1}px;
  }
  a,
  a:link,
  a:visited {
    cursor: pointer;
    text-decoration: underline;
    font-size: ${lightTheme.typography.size.desktop.body3}px;
    font-family: ${lightTheme.typography.font.simBold};
    font-weight: ${lightTheme.typography.weight.bold};
    color: ${lightTheme.colors.link};
  }
  a:hover,
  a:active {
    color: ${lightTheme.colors.linkAction};
  }
  textarea,
  input,
  button,
  select {
    border: none;
    font-family: ${lightTheme.typography.font.sim};
    font-size: ${lightTheme.typography.size.desktop.body3}px;
  }
  input:focus {
    outline: none;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${lightTheme.typography.font.npBold};
    font-weight: ${lightTheme.typography.weight.bold};
  }
  h1 {
    font-size: ${lightTheme.typography.size.desktop.heading}px;
  }
  h2 {
    font-size: ${lightTheme.typography.size.desktop.largeTitle}px;
  }
  h3 {
    font-size: ${lightTheme.typography.size.desktop.title1}px;
  }
  h4 {
    font-size: ${lightTheme.typography.size.desktop.title2}px;
  }
  h5 {
    font-size: ${lightTheme.typography.size.desktop.title3}px;
  }
`
export const LegacyStyles = css`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,800&display=swap');
  @font-face {
    font-family: 'Proxima';
    src: url(/static/fonts/proxima-semibold.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Proxima';
    src: url(/static/fonts/proxima-soft-regular.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  * {
    box-sizing: border-box;
  }
  html,
  body,
  #__next {
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 12px;
    font-family: 'Proxima', Helvetica, Arial, Sans-Serif;
    color: #373838;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  #root {
    width: 100%;
    display: block;
  }
  @media (max-width: 374px) {
    html,
    body {
      font-size: 10px !important;
    }
  }
  #__next {
    font-size: 1.6rem;
    position: relative;
  }
  .loadingContainer {
    background-color: #fff8f5;
  }
  .spinner {
    font-size: 1.6rem;
    height: 100%;
    display: flex;
    width: 6rem;
    color: #666666;
    align-self: center;
  }
  a,
  a:link,
  a:visited,
  a:active {
    text-decoration: none;
    cursor: pointer;
    color: #00aae7;
  }
  textarea,
  input,
  button,
  select {
    font-family: inherit;
    font-size: inherit;
  }
  @media (max-width: 767px) {
    textarea,
    input[type='text'],
    input[type='date'],
    input[type='tel'],
    input[type='email'],
    input[type='password'],
    select {
      -webkit-appearance: none;
      border: 1px solid #d0d0d0;
      border-radius: 0.4rem;
      font-size: 1.6rem;
      box-sizing: border-box;
    }
  }
  @font-face {
    font-family: 'Suisse Intl';
    src: url(/static/fonts/suisseintl-bold-webs.woff) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Suisse Intl';
    src: url(/static/fonts/suisseintl-book-webs.woff) format('woff');
    font-weight: 350;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Suisse Intl Mono Regular';
    src: url(/static/fonts/SuisseIntlMono-Regular-WebS.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Suisse Intl Mono Bold';
    src: url(/static/fonts/suisseintlmono-bold-webs.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Aventa';
    src: url(/static/fonts/aventa-bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Aventa';
    src: url(/static/fonts/aventa-semibold.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Gilroy';
    src: url(/static/fonts/gilroy-blackitalic.woff) format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }
`
