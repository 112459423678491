import t from './actionTypes'

export const defaultState = {
  article: {},
  previousOrders: []
}

const helpCenter = (state = defaultState, action = {}) => {
  switch (action.type) {
    case t.SET_HELP_CENTER_ARTICLE:
      return {
        ...state,
        article: action.article
      }

    case t.SET_PREVIOUS_ORDERS:
      return {
        ...state,
        previousOrders: action.previousOrders
      }

    default:
      return state
  }
}

export default helpCenter
