/**
 * Action types for loading
 */

export default {
  ADDRESS_LOADED: 'ADDRESS_LOADED',
  ADDRESS_LOADING: 'ADDRESS_LOADING',
  CANCEL_ORDER_LOADED: 'CANCEL_ORDER_LOADED',
  CANCEL_ORDER_LOADING: 'CANCEL_ORDER_LOADING',
  CART_LOADED: 'CART_LOADED',
  CART_LOADING: 'CART_LOADING',
  CREATING_SUPPORT_TICKET_LOADED: 'CREATING_SUPPORT_TICKET_LOADED',
  CREATING_SUPPORT_TICKET_LOADING: 'CREATING_SUPPORT_TICKET_LOADING',
  DELIVERIES_LOADED: 'DELIVERIES_LOADED',
  DELIVERIES_LOADING: 'DELIVERIES_LOADING',
  ETA_LOADED: 'ETA_LOADED',
  ETA_LOADING: 'ETA_LOADING',
  GROUP_LOADED: 'GROUP_LOADED',
  GROUP_LOADING: 'GROUP_LOADING',
  HELP_CENTER_ARTICLE_LOADED: 'HELP_CENTER_ARTICLE_LOADED',
  HELP_CENTER_ARTICLE_LOADING: 'HELP_CENTER_ARTICLE_LOADING',
  LOGIN_LOADED: 'LOGIN_LOADED',
  LOGIN_LOADING: 'LOGIN_LOADING',
  MENU_PRODUCTS_LOADED: 'MENU_PRODUCTS_LOADED',
  MENU_PRODUCTS_LOADING: 'MENU_PRODUCTS_LOADING',
  MMID_IMAGE_LOADED: 'MMID_IMAGE_LOADED',
  MMID_IMAGE_LOADING: 'MMID_IMAGE_LOADING',
  ORDER_CREATE_LOADED: 'ORDER_CREATE_LOADED',
  ORDER_CREATE_LOADING: 'ORDER_CREATE_LOADING',
  PHONE_CONFIRM_LOADED: 'PHONE_CONFIRM_LOADED',
  PHONE_CONFIRM_LOADING: 'PHONE_CONFIRM_LOADING',
  PHONE_INPUT_LOADED: 'PHONE_INPUT_LOADED',
  PHONE_INPUT_LOADING: 'PHONE_INPUT_LOADING',
  PRODUCT_LOADED: 'PRODUCT_LOADED',
  PRODUCT_LOADING: 'PRODUCT_LOADING',
  // @TODO: Fix typo with next two values
  PRODUCTS_LOADED: 'PRODUCT_LOADED',
  PRODUCTS_LOADING: 'PRODUCT_LOADING',
  QUOTE_LOADED: 'QUOTE_LOADED',
  QUOTE_LOADING: 'QUOTE_LOADING',
  RESET_PASSWORD_LOADED: 'RESET_PASSWORD_LOADED',
  RESET_PASSWORD_LOADING: 'RESET_PASSWORD_LOADING',
  SIGNUP_LOADED: 'SIGNUP_LOADED',
  SIGNUP_LOADING: 'SIGNUP_LOADING',
  STATE_ID_IMAGE_LOADED: 'STATE_ID_IMAGE_LOADED',
  STATE_ID_IMAGE_LOADING: 'STATE_ID_IMAGE_LOADING',
  STATE_ID_UPLOADED: 'STATE_ID_UPLOADED',
  STATE_ID_UPLOADING: 'STATE_ID_UPLOADING',
  TAX_FEES_LOADING: 'TAX_FEES_LOADING',
  TAX_FEES_LOADED: 'TAX_FEES_LOADED',
  USER_PROFILE_LOADED: 'USER_PROFILE_LOADED',
  USER_PROFILE_LOADING: 'USER_PROFILE_LOADING',
  USER_STATUS_LOADING: 'USER_STATUS_LOADING',
  USER_STATUS_LOADED: 'USER_STATUS_LOADED'
}
