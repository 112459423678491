/**
 * Eta reducer
 */
import t from './actionTypes'

export const defaultState = {}

const eta = (state = defaultState, action) => {
  switch (action.type) {
    case t.RESET_ETA:
      return defaultState

    case t.RECEIVE_ETA:
      return {
        ...action.payload
      }

    case t.SET_ETA_ERROR:
      return {
        ...state,
        error: action.payload
      }

    case t.CLEAR_ETA_ERROR:
      return {
        ...state,
        error: null
      }

    default:
      return state
  }
}

export default eta
