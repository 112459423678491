import device from '@eaze/observ-mobile'

import { Product } from 'bff/models/og/menu'
import { isAndroid, isIos } from 'helpers/is-mobile'

// env vars are accessed here, and should _only_ be accessed here
// note: all env vars here are exposed on the client, DO NOT list sensitive secrets here
// note: server/express.js code directly accesses process.env
// note: you cannot destructure process.env
const ADJUST_APP_TOKEN = process.env.ADJUST_APP_TOKEN
const API_BASE_URL = process.env.API_BASE_URL
const API_URL = process.env.API_URL
const MANUAL_VERIFY = Boolean(process.env.MANUAL_VERIFY)
const CACHE_TIMEOUT = parseInt(process.env.CACHE_TIMEOUT)
const LOCAL_IP_ADDRESS = process.env.LOCAL_IP_ADDRESS
const DISABLE_PERF_LOGGING = Boolean(process.env.DISABLE_PERF_LOGGING)
const EAZE_ENVIRONMENT = process.env.EAZE_ENVIRONMENT
const CLUSTER_NAME = process.env.CLUSTER_NAME
const FACEBOOK_APP_ID = process.env.FACEBOOK_APP_ID
const FIREBASE = process.env.FIREBASE
const GMAPS_KEY = process.env.GMAPS_KEY
const GOOP_BLOG_URL = process.env.GOOP_BLOG_URL
const PORT = process.env.PORT || 3000
const SEGMENT_KEY = process.env.SEGMENT_KEY
const SENTRY = process.env.SENTRY
const SIFT = process.env.SIFT
const SPLIT_BROWSER_KEY = process.env.SPLIT_BROWSER_KEY
const SPLIT_DEBUG = process.env.SPLIT_DEBUG
const TURNSTILE_SITE_KEY = process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY
const VERSION = process.env.VERSION
const WWW_BASE_URL = process.env.WWW_BASE_URL
const ZENDESK_CHAT_KEY = process.env.ZENDESK_CHAT_KEY
const ZENDESK_CUSTOM_FIELD_ID = process.env.ZENDESK_CUSTOM_FIELD_ID

const IMAGE_BASE = WWW_BASE_URL ? WWW_BASE_URL.replace('www', 'images') : ''

// generate the release/version string for sentry
// for staging/dev builds we append the env so that we don't overwrite prod source maps
// (source maps in Sentry are tracked by version name)
const SENTRY_RELEASE =
  EAZE_ENVIRONMENT === 'production' ? `eaze.com@${VERSION}` : `eaze.com@${VERSION}-${EAZE_ENVIRONMENT}`

const FIRST_TIME_PROMO = 'eazefirsttime'

const isBrowserContext = typeof window !== 'undefined'
const isMobile = isIos || isAndroid || device.mobile()

// Title of vaporizer category on iOS webview app for passing anti-vape app reviewers
const IOS_VAPORIZER_CATEGORY_NAME = 'Cartridges'

const ID_VERIFY_PLATFORM = 'web'
const ID_VERIFY_PROVIDER = 'Berbix'

const STATUS_CODE_ENTITY_TOO_LARGE = 413
const STATUS_CODE_DELIVERY_DECREASED = 470
const STATUS_CODE_DELIVERY_INCREASED = 471
const STATUS_CODE_SERVICE_UNAVAILABLE = 503

const DEFAULT_MENU_SLUG = 'default'
const MENU_SLUG = DEFAULT_MENU_SLUG
const WHITELISTED_MENUS = [MENU_SLUG]
const DELIVERY_TYPE_SHIPPING = 'Carrier' // we've removed wellness, but still need this var to display a user's past shipping orders
const EAZE_ORIGIN = 'EazeWeb'
const AVAILABLE_MENUS = [DEFAULT_MENU_SLUG]
const SITE_BASE_URL = WWW_BASE_URL

// place ID for 1400 Broadway, Oakland. Used as a fallback for sample menu
const DEFAULT_PLACE_ID = '4f03a0fe936bba0da38af6a15f77d64740db175c6a3200e68518d46868797adc_eaze'
const getSiteBaseUrl = () => SITE_BASE_URL

const SERVER_API_URL = `${API_URL}/api`

const referrerUrlTemplates = {
  md: 'eazemdapp://md#token='
}

// downtown SF location used as a fallback in case none is set
const defaultLocation = {
  latitude: 37.7868325,
  longitude: -122.4246491
}

function getApiUrl() {
  return isBrowserContext ? API_BASE_URL : SERVER_API_URL
}

function getItemQuantity(products: Product[]) {
  return products.reduce((totalQuantity, product: Product) => {
    return totalQuantity + product.quantity
  }, 0)
}

const getRoundedDecimal = (value: number) => {
  return value.toString().indexOf('.') === -1 ? value : Math.trunc(value * 10) / 10
}

const EMAIL = 'EMAIL'
const EXISTING_EMAIL_ERROR = 'User with the same email is already registered'
const EXISTING_PHONE_ERROR = 'User with the same mobile phone is already registered'
const WRONG_PASSWORD_ERROR = 'Wrong Email and Password combination'
const stripString = (string: string) => {
  if (!string || !string.toUpperCase) return ''
  return string.toUpperCase().replace(/\s/g, '')
}

const addressErrorMessages = {
  noInput: 'Where are we delivering your cannabis?',
  residentialOnlyError:
    'Due to state regulations, Eaze may not deliver cannabis to businesses or public places. Please update to a nearby residential address.'
}

// We need a way for a button in the menu carousel to open the intercom chat which means we need to know if the button needs an onClick handler or needs to be wrapped in a Link
// The URL for the button comes from WordPress so we check if the supplied URL equals the below constant to tell the difference
const CHAT_CAROUSEL_ID = '#intercom'

// Map of names for Split experiments
const SPLIT_EXPERIMENTS = {
  ACH_PROMO: 'ach_promo',
  AEROPAY_AGGREGATOR: 'dotcom_aeropay_aggregator',
  CATEGORY_PAGES: 'category_pages',
  CLIENT_SIDE_SEARCH: 'client_side_search',
  CLOUDFLARE_TURNSTILE: 'dotcom_login_turnstile',
  DRIVER_TIP: 'tipping',
  EXPIRED_ID: 'dotcom-exp-upload_expired_id',
  IOS_REMOVE_ALL_VAPES: 'ios_webview_remove_all_vapes',
  IOS_VAPES_REDIRECT_POPUP: 'ios_webview_vape_redirect_popup',
  IOS_VAPES_HIDE_MESSAGE_BANNER: 'dotcom-exp-ios_webview_hide_vapes_message_banner',
  IP_LOCATION_DETECTION: 'ip_location_detection',
  POTENCY_IN_PRODUCT_TILES: 'dotcom-exp-potency_in_product_tiles',
  PROFILE_PAGE_2FA: 'dotcom_profile_page_2fa_modal',
  REORDER_FEES: 'reorder_checkout_fees',
  REDESIGN: 'dotcom-redesign',
  SIDENAV_CBD: 'sidenav_cbd',
  SORT_AND_FILTER: 'sort-and-filter-potency-ranges',
  UPSELL_AT_CART: 'upsell_cart',
  UPSELL_AT_CART_V2: 'upsell_cart_v2',
  UPSELL_AT_CART_V3: 'upsell_cart_v3'
}
// Map of all possible treatments for each Split experiment
const SPLIT_TREATMENTS = {
  ACH_PROMO: {
    FIVE: 'five',
    TEN: 'ten',
    OFF: 'off'
  },
  AEROPAY_AGGREGATOR: {
    MX: 'MX',
    YODLEE: 'yodlee'
  },
  CATEGORY_PAGES: {
    ON: 'on',
    OFF: 'off'
  },
  CLIENT_SIDE_SEARCH: {
    ON: 'on',
    OFF: 'off'
  },
  CLOUDFLARE_TURNSTILE: {
    ON_ALWAYS: 'on_always_show',
    ON_INTERACTIVE: 'on_interactive_only',
    OFF: 'off'
  },
  DRIVER_TIP: {
    OFF: '',
    PERCENTAGE: 'percentage',
    FIXED: 'fixed'
  },
  EXPIRED_ID: {
    ON: 'on',
    OFF: 'off'
  },
  IOS_REMOVE_ALL_VAPES: {
    ON: 'on',
    OFF: 'off'
  },
  IOS_VAPES_HIDE_MESSAGE_BANNER: {
    ON: 'on',
    OFF: 'off'
  },
  IOS_VAPES_REDIRECT_POPUP: {
    ON: 'on',
    OFF: 'off'
  },
  IP_LOCATION_DETECTION: {
    ON: 'on',
    OFF: 'off'
  },
  POTENCY_IN_PRODUCT_TILES: {
    ON: 'on',
    OFF: 'off'
  },
  PROFILE_PAGE_2FA: {
    ON: 'on',
    OFF: 'off'
  },
  REDESIGN: {
    ON: 'on',
    OFF: 'off'
  },
  REORDER_FEES: {
    ON: 'reorder',
    OFF: 'default'
  },
  SIDENAV_CBD: {
    ON: 'on',
    OFF: 'off'
  },
  SORT_AND_FILTER: {
    ON: 'on',
    OFF: 'off'
  },
  UPSELL_AT_CART: {
    ON: 'on',
    OFF: 'off'
  },
  UPSELL_AT_CART_V2: {
    ON: 'on',
    OFF: 'off'
  },
  UPSELL_AT_CART_V3: {
    ON: 'on',
    OFF: 'off'
  }
}
// An object where each key:value pair looks like { EXPERIMENT_NAME: { treatment: OFF_TREATMENT, config: null } }
// Used for setting initial React Context in context/split.js and unit tests
const SPLIT_DEFAULTS = Object.entries(SPLIT_EXPERIMENTS).reduce((defaults, [experimentTitle, splitTitle]) => {
  defaults[splitTitle] = {
    treatment: SPLIT_TREATMENTS[experimentTitle].OFF,
    config: null
  }
  return defaults
}, {})

const splitConfig = {
  core: {
    authorizationKey: SPLIT_BROWSER_KEY,
    key: 'dummy' // https://github.com/splitio/javascript-client/issues/597#issuecomment-971915845
  },
  scheduler: {
    impressionsRefreshRate: 2 // in seconds
  },
  debug: SPLIT_DEBUG === 'true'
}

export {
  ADJUST_APP_TOKEN,
  API_BASE_URL,
  API_URL,
  AVAILABLE_MENUS,
  CACHE_TIMEOUT,
  CHAT_CAROUSEL_ID,
  CLUSTER_NAME,
  DEFAULT_MENU_SLUG,
  DEFAULT_PLACE_ID,
  DELIVERY_TYPE_SHIPPING,
  DISABLE_PERF_LOGGING,
  EAZE_ENVIRONMENT,
  EAZE_ORIGIN,
  EMAIL,
  EXISTING_EMAIL_ERROR,
  EXISTING_PHONE_ERROR,
  FACEBOOK_APP_ID,
  FIREBASE,
  FIRST_TIME_PROMO,
  GMAPS_KEY,
  GOOP_BLOG_URL,
  ID_VERIFY_PLATFORM,
  ID_VERIFY_PROVIDER,
  IMAGE_BASE,
  IOS_VAPORIZER_CATEGORY_NAME,
  LOCAL_IP_ADDRESS,
  MANUAL_VERIFY,
  MENU_SLUG,
  PORT,
  SEGMENT_KEY,
  SENTRY,
  SENTRY_RELEASE,
  SERVER_API_URL,
  SIFT,
  SITE_BASE_URL,
  SPLIT_BROWSER_KEY,
  SPLIT_DEBUG,
  SPLIT_DEFAULTS,
  SPLIT_EXPERIMENTS,
  SPLIT_TREATMENTS,
  STATUS_CODE_DELIVERY_DECREASED,
  STATUS_CODE_DELIVERY_INCREASED,
  STATUS_CODE_ENTITY_TOO_LARGE,
  STATUS_CODE_SERVICE_UNAVAILABLE,
  TURNSTILE_SITE_KEY,
  VERSION,
  WHITELISTED_MENUS,
  WRONG_PASSWORD_ERROR,
  WWW_BASE_URL,
  ZENDESK_CHAT_KEY,
  ZENDESK_CUSTOM_FIELD_ID,
  addressErrorMessages,
  defaultLocation,
  getApiUrl,
  getItemQuantity,
  getRoundedDecimal,
  getSiteBaseUrl,
  isBrowserContext,
  isMobile,
  referrerUrlTemplates,
  splitConfig,
  stripString
}
