/**
 * Action types for checkout
 */
export default {
  CLEAR_CHECKOUT_ERROR: 'CLEAR_CHECKOUT_ERROR',
  CLEAR_CHECKOUT_MESSAGE: 'CLEAR_CHECKOUT_MESSAGE',
  RESET_CHECKOUT: 'RESET_CHECKOUT',
  SET_CHECKOUT_MESSAGE: 'SET_CHECKOUT_MESSAGE',
  SET_CHECKOUT_NOTES: 'SET_CHECKOUT_NOTES',
  SET_DEPOT_MISMATCH: 'SET_DEPOT_MISMATCH',
  SET_CHECKOUT_ERROR: 'SET_CHECKOUT_ERROR',
  SET_DELIVERY_NOTES: 'SET_DELIVERY_NOTES',
  TOGGLE_CARD_DECLINE_DRAWER: 'TOGGLE_CARD_DECLINE_DRAWER',
  TOGGLE_ACH_FIX_DRAWER: 'TOGGLE_ACH_FIX_DRAWER',
  TRACK_CREDIT_OPTION: 'TRACK_CREDIT_OPTION',
  VIEW_CHECKOUT: 'VIEW_CHECKOUT'
}
