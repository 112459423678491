/**
 * Address reducer
 */

import t from './actionTypes'

// items == Array of {id: product ids, quantity}
// productsList == Array of descriptions with names, pricing, etc
export const defaultState = {
  open: false,
  suggestions: [],
  residentialOnlyError: false,
  redirect: false,
  redirectLocation: ''
}

const addressModalReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case t.TOGGLE_ADDRESS:
      return {
        ...state,
        open: !state.open
      }

    case t.SET_ADDRESS_SUGGESTIONS:
      return {
        ...state,
        suggestions: payload
      }

    case t.SET_ADDRESS_VISIBILITY:
      return {
        ...state,
        open: payload
      }

    case t.SET_RESIDENTIAL_ONLY_ERROR:
      return {
        ...state,
        residentialOnlyError: payload
      }

    case t.SET_SHOULD_REDIRECT:
      return {
        ...state,
        redirect: payload
      }

    case t.SET_REDIRECT_LOCATION:
      return {
        ...state,
        redirectLocation: payload
      }

    default:
      return state
  }
}

export default addressModalReducer
