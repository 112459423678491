/**
 * Auth reducer
 * It contains all the authentication related info
 */

import errorHandler from 'error-handler'
import t from 'redux/auth/actionTypes'

const MISSING_TOKEN_MSG = 'Unable to connect. Please log in again.'

// Default state. It should never change
export const defaultState = {
  err: null,
  existingSignupIdentifier: false,
  invalidLoginAttempt: false,
  invalidPasswordRecoveryAttempt: false,
  isFetching: false,
  passwordRecoveryIdentifier: null,
  phoneConfirmError: false,
  attemptProfileChange: '', // will be one of 'email', 'phone', or 'id'
  emailChangeAttempt: '', // stores the new email address user is attempting to change to
  phoneChangeAttempt: '' // stores the new phone number user is attempting to change to
}

const auth = (state = defaultState, action) => {
  switch (action.type) {
    case t.REQUEST_LOGIN:
      return {
        ...state,
        err: null
      }

    case t.RECEIVE_LOGIN_SUCCESS:
      // reset the state to default since a new login should not carry any dirty state
      return { ...defaultState, ...action.res }

    case t.RECEIVE_LOGIN_ERROR: {
      let err

      if (action.err) {
        err = action.err.message || MISSING_TOKEN_MSG
      }

      errorHandler(err)
      return {
        ...state,
        err: err
      }
    }

    case t.REQUEST_SIGNUP:
      return {
        ...state,
        isSigningUp: true,
        err: null
      }

    case t.RECEIVE_SIGNUP_ERROR:
      return {
        ...state,
        isSigningUp: false,
        err: action.err.message,
        existingSignupIdentifier: action.email
      }

    case t.RECEIVE_SIGNUP_SUCCESS:
      return {
        ...state,
        hasSignedUp: true,
        err: null
      }

    case t.RESET_PASSWORD:
      return state

    case t.SET_INVALID_LOGIN_ATTEMPT:
      return {
        ...state,
        invalidLoginAttempt: true
      }

    case t.RESET_INVALID_LOGIN_ATTEMPT:
      return {
        ...state,
        invalidLoginAttempt: false
      }

    case t.SET_INVALID_PASSWORD_RECOVERY_ATTEMPT:
      return {
        ...state,
        invalidPasswordRecoveryAttempt: action.identifier
      }

    case t.RESET_INVALID_PASSWORD_RECOVERY_ATTEMPT:
      return {
        ...state,
        invalidPasswordRecoveryAttempt: false
      }

    case t.SET_PASSWORD_RECOVERY_IDENTIFIER:
      return {
        ...state,
        passwordRecoveryIdentifier: action.identifier
      }

    case t.RESET_PASSWORD_RECOVERY_IDENTIFIER:
      return {
        ...state,
        passwordRecoveryIdentifier: null
      }

    case t.RESET_ERROR:
      return {
        ...state,
        err: null
      }

    case t.RESET_EXISTING_SIGNUP_IDENTIFIER:
      return {
        ...state,
        existingSignupIdentifier: false
      }

    case t.PHONE_CONFIRM_ERROR:
      return {
        ...state,
        phoneConfirmError: action.payload
      }

    case t.ATTEMPT_ID_CHANGE:
      return {
        ...state,
        attemptProfileChange: 'id'
      }

    case t.ATTEMPT_EMAIL_CHANGE:
      return {
        ...state,
        attemptProfileChange: 'email',
        emailChangeAttempt: action.payload
      }

    case t.ATTEMPT_PHONE_CHANGE:
      return {
        ...state,
        attemptProfileChange: 'phone',
        phoneChangeAttempt: action.payload
      }

    case t.RESET_PROFILE_CHANGE:
      return {
        ...state,
        attemptProfileChange: '',
        emailChangeAttempt: '',
        phoneChangeAttempt: ''
      }

    default:
      return state
  }
}

export default auth
